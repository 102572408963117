import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{`Similar Portfolios`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-monthly/"
            }}>{`All-Stars Monthly`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/antonacci-dual-momentum/"
            }}>{`Dual Momentum`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/faber-ivy-portfolio/"
            }}>{`Ivy Portfolio`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: balanced growth`}</li>
        <li parentName="ul">{`Type: momentum strategy`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking stocks and bonds`}</li>
        <li parentName="ul">{`Rebalancing schedule: monthly`}</li>
        <li parentName="ul">{`Taxation: 90% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $1,000`}</li>
      </ul>
      <p><em parentName="p">{`EngineeredPortfolio.com`}</em>{` is a blog site run by Chris Ludlow and Steve Hanly, two are aerospace/ mechanical engineers with an affinity to business and finance. They introduced their `}<em parentName="p">{`Accelerating Dual Momentum`}</em>{` strategy in mid-2018.`}</p>
      <p><em parentName="p">{`Accelerating Dual Momentum`}</em>{` aims to avoid the S&P 500's worst drawdowns but track the index closely during bullish periods. These properties make the strategy an easy choice: Investors are not missing out on gains in good years while being protected from excessive losses.`}</p>
      <p>{`Thanks to a monthly rebalancing schedule and to only holding a single ETF at any time, `}<em parentName="p">{`Accelerating Dual Momentum`}</em>{` has only minimal maintenance requirements.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><strong parentName="p">{`Source`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://engineeredportfolio.com/2018/05/02/accelerating-dual-momentum-investing/"
            }}>{`Engineered Portfolio`}</a></li>
        </ul>
        <p><strong parentName="p">{`Learn More`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.portfoliovisualizer.com/test-market-timing-model?s=y&coreSatellite=false&timingModel=6&startYear=1985&endYear=2018&initialAmount=10000&symbols=VFINX+VINEX&singleAbsoluteMomentum=false&volatilityTarget=9.0&downsideVolatility=false&outOfMarketAssetType=2&outOfMarketAsset=VUSTX&movingAverageSignal=1&movingAverageType=1&multipleTimingPeriods=true&periodWeighting=2&windowSize=1&windowSizeInDays=105&movingAverageType2=1&windowSize2=10&windowSizeInDays2=105&volatilityWindowSize=0&volatilityWindowSizeInDays=0&assetsToHold=1&allocationWeights=1&riskControl=false&riskWindowSize=10&riskWindowSizeInDays=0&rebalancePeriod=1&separateSignalAsset=false&tradeExecution=0&benchmark=VFINX&timingPeriods%5B0%5D=1&timingUnits%5B0%5D=2&timingWeights%5B0%5D=33&timingPeriods%5B1%5D=3&timingUnits%5B1%5D=2&timingWeights%5B1%5D=33&timingPeriods%5B2%5D=6&timingUnits%5B2%5D=2&timingWeights%5B2%5D=34&timingUnits%5B3%5D=2&timingWeights%5B3%5D=0&timingUnits%5B4%5D=2&timingWeights%5B4%5D=0&volatilityPeriodUnit=2&volatilityPeriodWeight=0"
            }}>{`Backtest on PortfolioVisualizer`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://dualmomentum.net/2018/09/13/perils-of-data-mining/"
            }}>{`Antonacci about Accelerating Dual Momentum`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/fbertram/TuringTrader/blob/develop/BooksAndPubs/Antonacci_DualMomentumInvesting.cs"
            }}>{`Source code`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`Accelerating Dual Momentum`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`trade a universe of ETFs tracking the S&P 500, all-world ex-US small caps, and long-term treasuries`}</li>
        <li parentName="ul">{`rebalance once per month`}</li>
        <li parentName="ul">{`calculate momentum scores as the sum of the 1-month, 3-month, and 6-month returns`}</li>
        <li parentName="ul">{`if either of the stock indices has a positive momentum score, invest in the asset with the higher score`}</li>
        <li parentName="ul">{`if both stock indices have negative scores, invest in treasuries instead`}</li>
      </ul>
      <p>{`This method closely resembles Gary Antonacci's `}<em parentName="p">{`Global Equity Momentum`}</em>{` strategy. However, Engineered Portfolio made essential changes to Antoncci's work:`}</p>
      <ul>
        <li parentName="ul">{`modify the momentum function to put additional weight on shorter periods. This change favors assets with accelerating momentum.`}</li>
        <li parentName="ul">{`replace the fund tracking large-cap all-world stocks with a more mid-cap focused ex-U.S. ETF. This change creates a better-diversified universe, allowing to stay longer in risky assets.`}</li>
        <li parentName="ul">{`increase treasury maturity from intermediate to long-term. Thanks to their negative correlation to the S&P 500, this change further improves the diversification of the universe.`}</li>
      </ul>
      <p>{`For a detailed description of `}<em parentName="p">{`Dual Momentum`}</em>{`, refer to Antonacci's book `}<em parentName="p">{`Dual Momentum Investing`}</em>{`. In addition, we recommend reading the blog post for a detailed explanation of the rationale behind Engineered Portfolio's modifications to Antonacci's strategy. It seems that Gary Antonacci is not a big fan of these modifications, as expressed in his blog post addressing `}<em parentName="p">{`Accelerating Dual Momentum`}</em>{`. However, we can appreciate Engineered Portfolio's reasoning.`}</p>
      <p>{`Our implementation slightly differs from the original blog post. To match the spirit of this site, we are using ETFs instead of mutual funds. Stephen Hanly has done the same in the code he published on Quantopian, which is unfortunately no longer available. Further, we assume that all orders are submitted `}<em parentName="p">{`after`}</em>{` the closing bell of the month's last trading day. This modification matches the spirit of our site, unlike the model used by PortfolioVisualizer, which trades `}<em parentName="p">{`at`}</em>{` the closing bell.`}</p>
      <p>{`The strategy is part of the `}<em parentName="p">{`TuringTrader.org`}</em>{` open-source project. For those interested in diving deeper into the details, we recommend checking out the C# source code in the repository.`}</p>
      <h2>{`Diversification`}</h2>
      <p>{`The strategy is well diversified in terms of company risk, as it only invests in broad indices. However, because the strategy only holds a single asset class at any time, `}<em parentName="p">{`Accelerating Dual Momentum`}</em>{` relies on the idea of `}<em parentName="p">{`serial diversification`}</em>{`. The strategy's low beta indicates that this has worked well over the backtested period. Nonetheless, investors should expect that the strategy's tail risk is identical to that of the individual asset classes. With the changes Engineered Portfolio made to Antonacci's original work, all three asset classes can be potentially highly volatile at times.`}</p>
      <p>{`The Monte-Carlo simulation shows that `}<em parentName="p">{`Accelerating Dual Momentum`}</em>{` has a substantial upside over the passive 60/40 benchmark. However, the sloping curve indicates that the strategy's returns are no more predictable than those of the benchmark. Further, the strategy's risk profile is taller than the benchmark, meaning higher investment risk. This intuitively makes sense because holding only one asset at a time implies that `}<em parentName="p">{`Accelerating Dual Momentum`}</em>{` cannot benefit from inverse correlations between assets.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`The objective for `}<em parentName="p">{`Accelerating Dual Momentum`}</em>{` was to closely track the S&P 500 during bullish markets while avoiding major downturns.`}</p>
      <p>{`The rolling returns show that the strategy might not consistently achieve its goals. While `}<em parentName="p">{`Accelerating Dual Momentum`}</em>{` handled the 2008 recession gracefully, it trailed the 60/40 benchmark between 2015 and 2017, with drawdowns of similar depth and duration as in 2008. Most likely, this is due to whipsaws caused by a few fast-paced market drawdowns, followed by swift recoveries.`}</p>
      <p>{`The tracking chart shows how the `}<em parentName="p">{`Accelerating Dual Momentum`}</em>{` continually gains over the 60/40 benchmark but gave up about four years of progress during a slump between 2015 and 2017. However, the strategy did regain its footing in 2017 and has been leading its benchmark since. Further, we note that the strategy handled the fast-paced events in late 2018 and spring 2020 well.`}</p>
      <p>{`We believe that `}<em parentName="p">{`Accelerating Dual Momentum`}</em>{` has slightly higher risks than a 60/40 portfolio based on the Ulcer Index and the Monte-Carlo analysis. However, investors are rewarded with substantially higher returns. Moreover, except for the 2015-2017 slump, the strategy held up very well over the backtested period, giving us confidence in its future performance.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p>{`When the strategy switches ETFs, it triggers taxable events. However, in our testing, `}<em parentName="p">{`Accelerating Dual Momentum`}</em>{` rarely held assets long enough to qualify for long-term capital gains. Thus, from a tax perspective, this strategy works best in tax-deferred accounts.`}</p>
      <p>{`The strategy invests in only a single ETF at a time. Therefore, a minimum capital of $1,000 is all you need to get started.`}</p>
    </Review>
    <Notes mdxType="Notes">
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      